const tsm_routes = [
    {
        path: "/main",
        alias: "/main",
        name: "Main",
        component: () =>
            import(/*webpackChunkName: 'group-tsm' */ "@/pages/TsmMainPage.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/driver-self-registration",
        alias: "/driver-self-registration",
        name: "DriverSelfRegistration",
        component: () =>
            import(/*webpackChunkName: 'group-tsm' */ "@/pages/TsmDriverSelfRegistrationPage.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/dashboard",
        alias: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(/*webpackChunkName: 'group-tsm' */ "@/pages/TsmDashboardPage.vue"),
        meta: {
            requiresAuth: true,
        },
    },
];

export default tsm_routes;
