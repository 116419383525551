<script setup>
import {computed, reactive} from 'vue'
import {RouterView} from 'vue-router'
import {useHead} from '@vueuse/head'
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n();
const siteData = reactive({
  //title: process.env.VUE_APP_TITLE,
  title: i18n.t("site_title"),
  description: i18n.t("site_desc"),
})
useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: `description`,
      content: computed(() => siteData.description),
    },
  ],
})
onbeforeunload = () => {
  store.dispatch("signout")
};
</script>

<template>
  <router-view></router-view>
</template>


<style>

</style>
