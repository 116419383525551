import {createApp} from 'vue'
import i18n from './i18n';
import store from './store';
import router from './router';
import axios from "axios";
import App from './App.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {createHead} from "@vueuse/head"
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {VueCookieNext} from 'vue-cookie-next'
import './styles/app.css';

import {
    faBarcodeRead,
    faFileCertificate,
    faLock,
    faTruckClock,
    faUnlock,
    faUser,
    faCogs,
    faEdit,
    faTrashAlt,
    faForklift,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faSquare,
    faSquareCheck,
    faTruck as fasrTruck,
} from "@fortawesome/sharp-regular-svg-icons";
import {
    faBars,
    faXmark,
    faRetweet,
    faCheck,
    faMagnifyingGlass,
    faCalendarDays,
    faTruck,
    faTruckArrowRight,
    faTruckContainer,
    faTruckContainerEmpty,
} from "@fortawesome/sharp-solid-svg-icons";
import {
    faTriangleExclamation,
    faClipboardCheck,
} from "@fortawesome/pro-regular-svg-icons"
import {
    faUserUnlock,
    faUserLock,
} from "@fortawesome/pro-duotone-svg-icons";
import {faHome} from "@fortawesome/sharp-solid-svg-icons/faHome";

library.add(faTruckClock, faTruck, fasrTruck, faTruckContainer, faTruckContainerEmpty, faTruckArrowRight,
    faBars, faBarcodeRead, faUser, faLock, faUnlock, faTriangleExclamation, faFileCertificate, faCogs, faEdit, faTrashAlt,
    faCalendarDays, faUserLock, faUserUnlock, faRetweet, faCheck, faXmark, faMagnifyingGlass, faSquare, faSquareCheck,
    faForklift, faClipboardCheck, faHome);


//axios.defaults.baseURL = "https://m9030.api.timeslot.cloud/tsmapi";
//axios.defaults.baseURL = "https://m9120.api.timeslot.cloud/tsmapi";
axios.defaults.baseURL = "https://m9200.api.timeslot.cloud/tsmapi";
//axios.defaults.baseURL = "https://m9060.api.timeslot.cloud/tsmapi";
//axios.defaults.baseURL = "https://qa9030.api.timeslot.cloud/tsmapi";
axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Origin': 'https://m9030.api.timeslot.cloud',
    //'Access-Control-Allow-Origin': 'https://m9120.api.timeslot.cloud',
    'Access-Control-Allow-Origin': 'https://m9200.api.timeslot.cloud',
    //'Access-Control-Allow-Origin': 'https://m9060.api.timeslot.cloud',
    //'Access-Control-Allow-Origin': 'https://qa9030.api.timeslot.cloud',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    //'X-Requested-With': 'XMLHttpRequest'
}
const head = createHead()
const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(head);
app.use(VueCookieNext);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');

VueCookieNext.config({expire: '1d'})
if (VueCookieNext.getCookie('dashboard') == null) {
    VueCookieNext.setCookie('dashboard', {
        sio: true,
        sir: false,
        sil: false,
        sif: false,
        sie: false,
    });
}
// set global cookie
VueCookieNext.setCookie('theme', 'default')
VueCookieNext.setCookie('las', Date.now().toString())