export default {
  "site_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSM-LOGIPACK"])},
  "site_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSM-System"])},
  "default_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSM"])},
  "default_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Backoffice"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung merken"])},
  "No authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])},
  "btn_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "Save and Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und schließen"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "Back to list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste"])},
  "Back to home page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
  "Sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortierung"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
  "User profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerprofil"])},
  "Customer authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenberechtigung"])},
  "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spediteur"])},
  "Loading area delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladeplatz Eingang"])},
  "Loading area pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladeplatz Ausgang"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung"])},
  "Pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung"])},
  "Delivery and Pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung / Abholung"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])},
  "Staff ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterkennung"])},
  "Staff options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiteroptionen"])},
  "Staff function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterfunktion"])},
  "License plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kfz-Kennzeichen"])},
  "Filter in timeslot overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter in der Terminübersicht"])},
  "Edit timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin bearbeiten"])},
  "Timeslot is locked by another user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin geperrt"])},
  "Lp Truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kfz-Kz"])},
  "License plate trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kfz-Kennzeichen Anhänger"])},
  "Lp Trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kfz-Knz"])},
  "vt-truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugmaschine"])},
  "vt-tractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugmaschine"])},
  "vt-semitrailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sattelauflieger"])},
  "vt-trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänger"])},
  "fassware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fassware"])},
  "short_fassware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fass"])},
  "short_code_xl_getraenke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code XL-G"])},
  "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "txt_loading_layer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einlagig"])},
  "txt_short_loading_layer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein"])},
  "txt_loading_layer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zweilagig"])},
  "txt_short_loading_layer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zwei"])},
  "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig"])},
  "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungültig"])},
  "Release Timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin freigeben"])},
  "Take over timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin übernehmen"])},
  "Timeslots assigned to me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Termine"])},
  "All booked timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebuchten Termine"])},
  "Loading status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Verladung"])},
  "Reset loading status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladestatus zurücksetzen"])},
  "Check load securing before exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladungssicherung vor Ausfahrt prüfen"])},
  "Click here if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier klicken wenn"])},
  "Load securing checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladungssicherung geprüft"])},
  "Reset load securing check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladungssicherung zurücksetzen"])},
  "Select loading location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladestandort wählen"])},
  "Select default for employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Stapler selektieren"])},
  "Loading Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladezone"])},
  "Type of loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Verladung"])},
  "Loading area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich der Verladung"])},
  "url_imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.logipack.com/impressum"])},
  "url_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.logipack.com/datenschutz"])},
  "9030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ West"])},
  "9031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Südwest"])},
  "9032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Nord"])},
  "9033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Ost"])},
  "txt_lkwart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugart"])},
  "9030_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9030_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ West - Hamm"])},
  "9031_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9031_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Südwest"])},
  "9032_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9032_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Nord"])},
  "9033_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9033_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Ost"])},
  "0030i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ West - Hamm"])},
  "0031i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Südwest"])},
  "0032i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Nord"])},
  "0033i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ost - Leergut Anlieferung / Leergut Tausche"])},
  "1033i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ost - Vollgut"])},
  "0030i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0031i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0032i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0033i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "1033i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0030i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0031i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0032i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0033i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "1033i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0030i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0031i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0032i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0033i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "1033i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0030i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0031i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0032i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0033i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "1033i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0030i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0031i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0032i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0033i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "1033i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0030i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0031i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0032i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0033i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "1033i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0030i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0031i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0032i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0033i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "1033i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0030i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0031i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0032i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0033i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "1033i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0030i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0031i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0032i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0033i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "1033i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0030i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0031i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0032i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0033i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "1033i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0030i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0031i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0032i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0033i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "1033i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0030i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0031i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0032i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0033i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "1033i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0030i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0031i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0032i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0033i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "1033i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0030i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0031i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0032i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0033i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "1033i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0030i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0031i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0032i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0033i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "1033i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0030i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0031i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0032i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0033i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "1033i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0030i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0031i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0032i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0033i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "1033i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0030i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg3"])},
  "0031i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0031i_txt_eg3"])},
  "0032i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0032i_txt_eg3"])},
  "0033i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0033i_txt_eg3"])},
  "1033i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1033i_txt_eg3"])},
  "0030i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0031i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0032i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0033i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "1033i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0030i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0031i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0032i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0033i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "1033i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0030i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0031i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0032i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0033i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "1033i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "9030_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9031_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9032_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9033_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9030_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9031_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9032_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9033_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9030_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9031_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9032_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9033_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9030_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9031_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9032_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9033_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9030_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9031_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9032_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9033_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9030_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9031_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9032_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9033_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9030_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9031_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9032_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9033_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9030_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9031_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9032_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9033_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9030_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9031_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9032_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9033_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9030_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9031_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9032_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9033_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9030_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9031_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9032_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9033_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9030_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9031_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9032_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9033_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9030_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9031_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9032_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9033_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "0030i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 2"])},
  "0030i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 2"])},
  "0030i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 19"])},
  "0030i_lane_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 19"])},
  "0030i_lane_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweichspur"])},
  "0031i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spur 1"])},
  "0031i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spur 2"])},
  "0031i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweich Spur"])},
  "0032i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 1"])},
  "0032i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweich Spur"])},
  "0033i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 1"])},
  "0033i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 2"])},
  "0033i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 3"])},
  "0033i_lane_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LG Ausweich 1"])},
  "0033i_lane_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LG Ausweich 2"])},
  "1033i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 1"])},
  "1033i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 2"])},
  "1033i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 3"])},
  "1033i_lane_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VG Ausweich"])},
  "1033i_lane_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1033i_lane_5"])},
  "lbl_customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "hdr_dashboard_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet"])},
  "hdr_dashboard_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "hdr_dashboard_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "hdr_dashboard_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgefertigt"])},
  "count_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "count_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "count_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartet"])},
  "count_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "count_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladen"])},
  "count_exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
  "page_contact_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihre Ansprechpartner für Rückfragen<br>", _interpolate(_named("SUPPORT_MAIL"))])},
  "Your account has been locked due to too many login attempts.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wurde aufgrund zu vieler Anmeldeversuche gesperrt."])},
  "Email or password incorrect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email oder Passwort fehlerhaft"])},
  "User is locked please contact support.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist gesperrt, wenden Sie sich bitte an den Support."])},
  "An unknown error has occurred. Please contact the support.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein unbekannter Fehler aufgetreten.<br>Bitte kontaktieren Sie den Support."])},
  "Please contact support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Support kontaktieren"])},
  "No authorization for the internal area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechigung für den internen Bereich"])},
  "Please contact administration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Administration kontaktieren."])},
  "No timeslots found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Termine gefunden"])},
  "SELF_REGISTRATION_NOT_PERMITTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin ist ungültig."])},
  "SELF_REGISTRATION_NOT_PERMITTED_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Disposition kontaktieren"])},
  "TIMESLOT_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin wurde nicht gefunden."])},
  "TIMESLOT_NOT_FOUND_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Daten überprüfen oder in der Disposition melden."])},
  "WRONG_SEARCH_VALUES_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Eingabe überprüfen"])},
  "WRONG_SEARCH_VALUES_1_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die erforderlichen Werte sind nicht vollständig oder inkorrekt."])},
  "WRONG_SEARCH_VALUES_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Eingabe überprüfen"])},
  "WRONG_SEARCH_VALUES_2_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die erforderlichen Werte sind nicht vollständig oder inkorrekt."])},
  "WRONG_SEARCH_VALUES_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Eingabe überprüfen"])},
  "WRONG_SEARCH_VALUES_3_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die erforderlichen Werte sind nicht vollständig oder inkorrekt."])},
  "QRCODE_NOT_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der QR-Code ist nicht korrekt."])},
  "QRCODE_NOT_VALID_cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den korrekten QR-Code scannen oder eingeben."])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "Polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnisch"])}
}